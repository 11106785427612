<template>
        <div class="row container-fluid">
            <div class="col">
                <div class="card shadow-sm">
                <div class="card-body">
                    <div
                    class="px-3"
                    style="border-left: 3px solid #ffc107 !important;"
                    >
                    <span class="">TOTAL NO. OF STUDENTS</span>
                    <div>
                        <h3 class="d-inline">
                            {{ addComma(totalNumberStudents) }}
                        </h3>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="col">
                <div class="card shadow-sm">
                <div class="card-body">
                    <div
                    class="px-3"
                    style="border-left: 3px solid #321fdb  !important;"
                    >
                    <span class="">TOTAL APPROXIMATE TREES PER STUDENT</span>
                    <div>
                        <h3 class="d-inline">
                            {{ addComma(totalNumberAppTreesPerStudent) }}
                        </h3>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="col">
                <div class="card shadow-sm">
                <div class="card-body">
                    <div
                    class="px-3"
                    style="border-left: 3px solid #228B22 !important;"
                    >
                    <span class="">TOTAL TREES PLANTED</span>
                    <div>
                        <h3 class="d-inline">
                            {{ addComma(totalTreesPlanted) }}
                        </h3>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="col">
                <div class="card shadow-sm">
                <div class="card-body">
                    <div
                    class="px-3"
                    style="border-left: 3px solid #FF0000 !important;"
                    >
                    <span class="">REMAINING TREES TO BE PLANTED</span>
                    <div>
                        <h3 class="d-inline">
                            {{ addComma(oneM - totalTreesPlanted) }}
                        </h3>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
</template>
<script>


export default {
  data() {
    return {
      loading: false,
      list_heis: [],
      totalNumberStudents: '',
      totalNumberAppTreesPerStudent: '',
      totalTreesPlanted: '',
      oneM: 1000000
    }
  },
  mounted() {
   this.getTotalTrees();
   this.getListHEIs();
  },
  methods: {

  addComma(amount) {
    let num_parts = amount.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return num_parts.join(".");
  },
    async getTotalTrees() {
        try {
            const response = await axios.get('api/get-total-trees-planted')
            .then((response) => {

                let total_trees = response.data.total_trees; 
                let len = total_trees.length;
                let totalTreesPlantd = 0;
                for(let i=0; i<len; i++) {
                  totalTreesPlantd = totalTreesPlantd + total_trees[i].no_trees;
                } 
                this.totalTreesPlanted = totalTreesPlantd;        
          })
        } catch(error) {
            console.log("err", error);
            alert('Something went wrong!');
        }
    },
      async getListHEIs() {
          try {
              const response = await axios.get('api/get-list-heis')
              .then((response) => {
                this.list_heis = response.data.list_heis;  
                console.log("list heis", this.list_heis);
                
                let len = this.list_heis.length;
                let totalNumberStdents = 0;
                let totalNumberAppTreesPerStdent = 0;
                for(let i=0; i<len; i++) {
                  totalNumberStdents = totalNumberStdents + this.list_heis[i].no_students;
                  totalNumberAppTreesPerStdent = totalNumberAppTreesPerStdent + this.list_heis[i].app_trees_per_student;
                }
                this.totalNumberStudents = totalNumberStdents;
                this.totalNumberAppTreesPerStudent = totalNumberAppTreesPerStdent;
              })
          } catch(error) {
              console.log("err", error);
              alert('Something went wrong!');
          }
      },

  }
}
</script>

<style>

</style>