<template>
    <div class="main">
        
        <div class="card shadow-sm">
            <div class="card-body">
                    <div class="d-flex justify-content-start my-4">
                        <label class="switch">
                            <input type="checkbox" @click="toggleCheckbox">
                            <div class="slider round"></div>
                        </label>
                        <span class="text-left ml-2 font-weight-bold" v-if="!checkbox">Switch to Monthly</span>
                        <span class="text-left ml-2 font-weight-bold" v-else>Switch to Yearly</span>
                    </div>
                <div class="row" v-if="!checkbox">
                    <div class="col-4 my-auto d-flex flex-row">
                        <img
                        src="@/assets/images/login-page-ched-logo.png"
                        alt=""
                        class="mx-3"
                        width="80"
                        length="80"
                        />
                        <div class="my-auto">
                        <h5 class="mb-0">
                        PER PAP/PROJECT
                        </h5>
                        <span class="text-muted">
                            <!-- You can view realtime data and statistics together with graphs
                            from different Higher Education Institution (HEI) in Caraga
                            Region. -->
                        </span>
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="form-group">
                        <label class="font-weight-bold">PROJECT</label>
                        <select class="form-control" v-model="per_project_year.program">
                            <option  :value="program.budget_program_id" v-for="program in programs" :key="program.budget_program_id">{{ program.budget_name }}</option>
                        </select>
                        </div>
                    </div>
                    <div class="col-2">
                        <label class="font-weight-bold">Appropriation <span class="text-primary">(optional)</span></label>
                        <select class="form-select form-control" v-model="per_project_year.appro">
                            <option value="2">Continuing</option>
                            <option value="1">Current</option>
                        </select>  
                    </div>
                    <div class="col-2">
                        <label class="font-weight-bold">Funding Source <span class="text-primary">(optional)</span></label>
                        <select class="form-select form-control" v-model="per_project_year.funding_source_id" >
                                <option :value="funding_source.funding_source_id" v-for="funding_source in funding_sources" :key="funding_source.funding_source_id">{{funding_source.funding_name}}</option>
                        </select>  
                    </div>
                    <div class="col-2">
                        <div class="form-group">
                        <label class="font-weight-bold">Year</label>
                        <select class="form-control" v-model="per_project_year.year">
                            <option value="2021" selected>2021</option>
                             <option value="2022" >2022</option>
                            <option value="2023" >2023</option>
                            <option value="2024" >2024</option>                        
                        </select>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="row">
                        <div class="col-2 my-auto d-flex flex-row">
                            <img
                            src="@/assets/images/login-page-ched-logo.png"
                            alt=""
                            class="mx-3"
                            width="80"
                            length="80"
                            />
                            <div class="my-auto">
                            <h5 class="mb-0">
                            PER PAP/PROJECT
                            </h5>
                            <span class="text-muted">
                                <!-- You can view realtime data and statistics together with graphs
                                from different Higher Education Institution (HEI) in Caraga
                                Region. -->
                            </span>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                            <label class="font-weight-bold">PROJECT</label>
                            <select class="form-control" v-model="per_project_months.program">
                                <option  :value="program.budget_program_id" v-for="program in programs" :key="program.budget_program_id">{{ program.budget_name }}</option>
                            </select>
                            </div>
                        </div>
                        <div class="col-2">
                            <label class="font-weight-bold">Funding Source <span class="text-primary">(optional)</span></label>
                            <select class="form-select form-control" v-model="per_project_months.funding_source_id" >
                                    <option :value="funding_source.funding_source_id" v-for="funding_source in funding_sources" :key="funding_source.funding_source_id">{{funding_source.funding_name}}</option>
                            </select>  
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                            <label class="font-weight-bold">From</label>
                            <input type="date" v-model="per_project_months.from" class="form-control"/>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                            <label class="font-weight-bold">To</label>
                            <input type="date" v-model="per_project_months.to" class="form-control"/>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                            <label class="font-weight-bold">Year</label>
                            <select class="form-control" v-model="per_project_months.year">
                                <option value="2021" selected>2021</option>
                                <option value="2022" >2022</option>
                                <option value="2023" >2023</option>
                                <option value="2024" >2024</option>                        
                            </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-10"></div>
                        <div class="col-2">
                            <label class="font-weight-bold">Appropriation <span class="text-primary">(optional)</span></label>
                            <select class="form-select form-control" v-model="per_project_months.appro">
                                <option value="2">Continuing</option>
                                <option value="1">Current</option>
                            </select>  
                        </div>
                    </div>
                </div>
            </div>
            <div class="row container-fluid" v-if="!checkbox">
            <div class="col">
                <div class="card shadow-sm">
                <div class="card-body">
                    <div
                    class="px-3"
                    style="border-left: 3px solid #ffc107 !important;"
                    >
                    <span class="">TOTAL ALLOTMENT</span>
                    <div>
                        <h4 v-if="loading_project">Loading...</h4>
                        <h3 class="d-inline" v-else>
                        {{ addComma(convertToPeso(roundOf(per_project_total_allotment))) }}
                        </h3>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="col">
                <div class="card shadow-sm">
                <div class="card-body">
                    <div
                    class="px-3"
                    style="border-left: 3px solid #321fdb  !important;"
                    >
                    <span class="">TOTAL OBLIGATION</span>
                    <div>
                        <h4 v-if="loading_project">Loading...</h4>
                        <h3 class="d-inline" v-else>
                        {{ addComma(convertToPeso(roundOf(per_project_total_obligation))) }}
                        
                        </h3>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="col">
                <div class="card shadow-sm">
                <div class="card-body">
                    <div
                    class="px-3"
                    style="border-left: 3px solid #FF0000 !important;"
                    >
                    <span class="">BALANCE-UNOBLIGATED</span>
                    <div>
                        <h4 v-if="loading_project">Loading...</h4>
                        <h3 class="d-inline" v-else>
                        {{ addComma(convertToPeso(roundOf(per_project_total_allotment - per_project_total_obligation))) }}
                        </h3>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="col">
                <div class="card shadow-sm">
                <div class="card-body">
                    <div
                    class="px-3"
                    style="border-left: 3px solid #2eb85c !important;"
                    >
                    <span class="">TOTAL DISBURSEMENT</span>
                    <div>
                        <h4 v-if="loading_project">Loading...</h4>
                        <h3 class="d-inline" v-else>
                        {{ addComma(convertToPeso(roundOf(per_project_total_disbursed))) }}
                        </h3>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="col">
                <div class="card shadow-sm">
                <div class="card-body">
                    <div
                    class="px-3"
                    style="border-left: 3px solid #FF0000 !important;"
                    >
                    <span class="">UNPAID OBLIGATION</span>
                    <div>
                        <h4 v-if="loading_project">Loading...</h4>
                        <h3 class="d-inline" v-else>
                        {{ addComma(convertToPeso(roundOf(per_project_total_obligation - per_project_total_disbursed))) }}
                        </h3>
                    </div>
                    </div>
                </div>
                </div>
            </div>

            </div>
            <div class="row container-fluid" v-else>
                <div class="col">
                    <div class="card shadow-sm">
                    <div class="card-body">
                        <div
                        class="px-3"
                        style="border-left: 3px solid #321fdb  !important;"
                        >
                        <span class="">TOTAL OBLIGATION</span>
                        <div>
                            <h4 v-if="loading_months">Loading...</h4>
                            <h3 class="d-inline" v-else>
                            {{ addComma(convertToPeso(roundOf(per_project_total_obligation_months))) }}
                            </h3>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card shadow-sm">
                    <div class="card-body">
                        <div
                        class="px-3"
                        style="border-left: 3px solid #2eb85c !important;"
                        >
                        <span class="">TOTAL DISBURSEMENT</span>
                        <div>
                            <h4 v-if="loading_months">Loading...</h4>
                            <h3 class="d-inline" v-else>
                            {{ addComma(convertToPeso(roundOf(per_project_total_disbursed_months))) }}
                            </h3>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card shadow-sm">
                    <div class="card-body">
                        <div
                        class="px-3"
                        style="border-left: 3px solid #FF0000 !important;"
                        >
                        <span class="">UNPAID OBLIGATION</span>
                        <div>
                            <h4 v-if="loading_months">Loading...</h4>
                            <h3 class="d-inline" v-else>
                            {{ addComma(convertToPeso(roundOf(per_project_total_obligation_months - per_project_total_disbursed_months))) }}
                            </h3>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="card shadow-sm">
            <div class="card-body">
                    <div class="d-flex justify-content-start my-4">
                        <label class="switch">
                            <input type="checkbox" @click="toggleCheckboxObj">
                            <div class="slider round"></div>
                        </label>
                        <span class="text-left ml-2 font-weight-bold" v-if="!checkbox_obj">Switch to Monthly</span>
                        <span class="text-left ml-2 font-weight-bold" v-else>Switch to Yearly</span>
                    </div>
                <div class="row" v-if="!checkbox_obj">
                    <div class="col-2 my-auto d-flex flex-row">
                        <img
                        src="@/assets/images/login-page-ched-logo.png"
                        alt=""
                        class="mx-3"
                        width="80"
                        length="80"
                        />
                        <div class="my-auto">
                        <h5 class="mb-0">
                        PER PAP/PROJECT | PER OBJECT CODE
                        </h5>
                        <span class="text-muted">
                            <!-- You can view realtime data and statistics together with graphs
                            from different Higher Education Institution (HEI) in Caraga
                            Region. -->
                        </span>
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="form-group">
                        <label class="font-weight-bold">PROJECT</label>
                        <select class="form-control" v-model="per_project_object_code_year.program">
                            <option  :value="program.budget_program_id" v-for="program in programs" :key="program.budget_program_id">{{ program.budget_name }}</option>
                        </select>
                        </div>
                    </div>
                    <div class="col-2">
                            <label class="font-weight-bold">Appropriation <span class="text-primary">(optional)</span></label>
                            <select class="form-select form-control" v-model="per_project_object_code_year.appro">
                                <option value="2">Continuing</option>
                                <option value="1">Current</option>
                            </select>  
                        </div>
                    <div class="col-2">
                        <label class="font-weight-bold">Funding Source <span class="text-primary">(optional)</span></label>
                        <select class="form-select form-control" v-model="per_project_object_code_year.funding_source_id" >
                                <option :value="funding_source.funding_source_id" v-for="funding_source in funding_sources" :key="funding_source.funding_source_id">{{funding_source.funding_name}}</option>
                        </select>  
                    </div>
                    <div class="col-2">
                        <div class="form-group">
                        <label class="font-weight-bold">OBJECT CODE</label>
                        <select class="form-control" v-model="per_project_object_code_year.objCode">
                            <option  :value="object_code.object_code_id" v-for="object_code in object_codes" :key="object_code.object_code_id">{{ object_code.object_name }}</option>
                        </select>
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="form-group">
                        <label class="font-weight-bold">Year</label>
                      <select class="form-control" v-model="per_project_object_code_year.year">
                            <option value="2021" selected>2021</option>
                             <option value="2022" >2022</option>
                            <option value="2023" >2023</option>
                            <option value="2024" >2024</option>
                        </select>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="row">
                        <div class="col-2 my-auto d-flex flex-row">
                            <img
                            src="@/assets/images/login-page-ched-logo.png"
                            alt=""
                            class="mx-3"
                            width="80"
                            length="80"
                            />
                            <div class="my-auto">
                            <h5 class="mb-0">
                            PER PAP/PROJECT | PER OBJECT CODE
                            </h5>
                            <span class="text-muted">
                                <!-- You can view realtime data and statistics together with graphs
                                from different Higher Education Institution (HEI) in Caraga
                                Region. -->
                            </span>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                            <label class="font-weight-bold">PROJECT</label>
                            <select class="form-control" v-model="per_project_object_code_months.program">
                                <option  :value="program.budget_program_id" v-for="program in programs" :key="program.budget_program_id">{{ program.budget_name }}</option>
                            </select>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                            <label class="font-weight-bold">OBJECT CODE</label>
                            <select class="form-control" v-model="per_project_object_code_months.objCode">
                                <option  :value="object_code.object_code_id" v-for="object_code in object_codes" :key="object_code.object_code_id">{{ object_code.object_name }}</option>
                            </select>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                            <label class="font-weight-bold">From</label>
                            <input type="date" v-model="per_project_object_code_months.obj_from" class="form-control"/>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                            <label class="font-weight-bold">To</label>
                            <input type="date" v-model="per_project_object_code_months.obj_to" class="form-control"/>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                            <label class="font-weight-bold">Year</label>
                        <select class="form-control" v-model="per_project_object_code_months.year">
                                <option value="2021" selected>2021</option>
                                <option value="2022" >2022</option>
                                <option value="2023" >2023</option>
                                <option value="2024" >2024</option>
                            </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8"></div>
                        <div class="col-2">
                            <label class="font-weight-bold">Funding Source <span class="text-primary">(optional)</span></label>
                            <select class="form-select form-control" v-model="per_project_object_code_months.funding_source_id" >
                                    <option :value="funding_source.funding_source_id" v-for="funding_source in funding_sources" :key="funding_source.funding_source_id">{{funding_source.funding_name}}</option>
                            </select>  
                        </div>
                        <div class="col-2">
                            <label class="font-weight-bold">Appropriation <span class="text-primary">(optional)</span></label>
                            <select class="form-select form-control" v-model="per_project_object_code_months.appro">
                                <option value="2">Continuing</option>
                                <option value="1">Current</option>
                            </select>  
                        </div>
                    </div>
                </div>
            </div>
            <div class="row container-fluid" v-if="!checkbox_obj">
            <div class="col">
                <div class="card shadow-sm">
                <div class="card-body">
                    <div
                    class="px-3"
                    style="border-left: 3px solid #ffc107 !important;"
                    >
                    <span class="">TOTAL ALLOTMENT</span>
                    <div>
                        <h4 v-if="loading_objCode">Loading...</h4>
                        <h3 class="d-inline" v-else>
                        {{ addComma(convertToPeso(roundOf(per_project_object_code_total_allotment + per_project_object_code_total_borrowed))) }}
                        </h3>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="col">
                <div class="card shadow-sm">
                <div class="card-body">
                    <div
                    class="px-3"
                    style="border-left: 3px solid #ffc107 !important;"
                    >
                    <span class="">TOTAL ALLOTMENT BORROWED</span>
                    <div>
                        <h4 v-if="loading_objCode">Loading...</h4>
                        <h3 class="d-inline" v-else>
                        {{ addComma(convertToPeso(roundOf(per_project_object_code_total_budget_borrowed))) }}
                        </h3>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="col">
                <div class="card shadow-sm">
                <div class="card-body">
                    <div
                    class="px-3"
                    style="border-left: 3px solid #321fdb  !important;"
                    >
                    <span class="">TOTAL OBLIGATION</span>
                    <div>
                        <h4 v-if="loading_objCode">Loading...</h4>
                        <h3 class="d-inline" v-else>
                        {{ addComma(convertToPeso(roundOf((per_project_object_code_total_obligation) + per_project_object_code_total_borrowed))) }}
                        </h3>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="col">
                <div class="card shadow-sm">
                <div class="card-body">
                    <div
                    class="px-3"
                    style="border-left: 3px solid #FF0000  !important;"
                    >
                    <span class="">BALANCE-UNOBLIGATED</span>
                    <div>
                        <h4 v-if="loading_objCode">Loading...</h4>
                        <h3 class="d-inline" v-else>
                        {{ addComma(convertToPeso(roundOf(per_project_object_code_total_allotment - per_project_object_code_total_obligation))) }}
                        </h3>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="col">
                <div class="card shadow-sm">
                <div class="card-body">
                    <div
                    class="px-3"
                    style="border-left: 3px solid #2eb85c !important;"
                    >
                    <span class="">TOTAL DISBURSEMENT</span>
                    <div>
                        <h4 v-if="loading_objCode">Loading...</h4>
                        <h3 class="d-inline" v-else>
                        {{ addComma(convertToPeso(roundOf(per_project_object_code_total_disbursed))) }}
                        </h3>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="col">
                <div class="card shadow-sm">
                <div class="card-body">
                    <div
                    class="px-3"
                    style="border-left: 3px solid #FF0000 !important;"
                    >
                    <span class="">UNPAID OBLIGATION</span>
                    <div>
                        <h4 v-if="loading_objCode">Loading...</h4>
                        <h3 class="d-inline" v-else>
                        {{ addComma(convertToPeso(roundOf((per_project_object_code_total_obligation - per_project_object_code_total_disbursed) + per_project_object_code_total_borrowed))) }}
                        </h3>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="row container-fluid" v-else>
            <div class="col">
                <div class="card shadow-sm">
                <div class="card-body">
                    <div
                    class="px-3"
                    style="border-left: 3px solid #ffc107 !important;"
                    >
                    <span class="">TOTAL ALLOTMENT BORROWED</span>
                    <div>
                        <h4 v-if="loading_objCode_months">Loading...</h4>
                        <h3 class="d-inline" v-else>
                        {{ addComma(convertToPeso(roundOf(per_project_object_code_total_budget_borrowed_months))) }}
                        </h3>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="col">
                <div class="card shadow-sm">
                <div class="card-body">
                    <div
                    class="px-3"
                    style="border-left: 3px solid #321fdb  !important;"
                    >
                    <span class="">TOTAL OBLIGATION</span>
                    <div>
                        <h4 v-if="loading_objCode_months">Loading...</h4>
                        <h3 class="d-inline" v-else>
                        {{ addComma(convertToPeso(roundOf((per_project_object_code_total_obligation_months) + per_project_object_code_total_borrowed_months))) }}
                        </h3>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="col">
                <div class="card shadow-sm">
                <div class="card-body">
                    <div
                    class="px-3"
                    style="border-left: 3px solid #2eb85c !important;"
                    >
                    <span class="">TOTAL DISBURSEMENT</span>
                    <div>
                        <h4 v-if="loading_objCode_months">Loading...</h4>
                        <h3 class="d-inline" v-else>
                        {{ addComma(convertToPeso(roundOf(per_project_object_code_total_disbursed_months))) }}
                        </h3>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="col">
                <div class="card shadow-sm">
                <div class="card-body">
                    <div
                    class="px-3"
                    style="border-left: 3px solid #FF0000 !important;"
                    >
                    <span class="">UNPAID OBLIGATION</span>
                    <div>
                        <h4 v-if="loading_objCode_months">Loading...</h4>
                        <h3 class="d-inline" v-else>
                        {{ addComma(convertToPeso(roundOf((per_project_object_code_total_obligation_months - per_project_object_code_total_disbursed_months) + per_project_object_code_total_borrowed_months))) }}
                        </h3>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        
    </div>
</template>
<style lang="scss" scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 19px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #321fdb;
}

input:focus + .slider {
  box-shadow: 0 0 1px #321fdb;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
<script>
export default {
    name: 'Chart',
    data() {
        return {
            checkbox: false,
            checkbox_obj: false,
            programs: [],
            object_codes: [],
            per_project_year: {
                program: '',
                appro: '',
                funding_source_id: '',
                year: '',
            },
            per_project_months: {
                program: '',
                appro: '',
                funding_source_id: '',
                year:'',
                from: '',
                to: '',
            },
            per_project_object_code_year: {
                program: '',
                appro: '',
                funding_source_id: '',
                objCode: '',
                year: '',
            },
            per_project_object_code_months: {
                program: '',
                appro: '',
                funding_source_id: '',
                objCode: '',
                obj_from: '',
                obj_to: '',
                year: ''
            },
            per_project_total_allotment: '',
            per_project_object_code_total_allotment: '',
            per_project_total_obligation: '',
            per_project_total_obligation_months: '',
            per_project_object_code_total_obligation: '',
            per_project_object_code_total_obligation_months: '',
            per_project_total_disbursed: '',
            per_project_total_disbursed_months: '',
            per_project_object_code_total_disbursed: '',
            per_project_object_code_total_disbursed_months: '',
            per_project_object_code_total_budget_borrowed: '',
            per_project_object_code_total_budget_borrowed_months: '',
            per_project_object_code_total_borrowed: '',
            per_project_object_code_total_borrowed_months: '',
            loading_project: true,
            loading_objCode: true,
            loading_objCode_months: true,
            loading_months: true,
            funding_sources: []

        }
    },
    watch: {
        'per_project_months.from'(val) {
            if(val) {
                this.getCountTotalObligationPerProjectMonths();
                this.getCountTotalDisbursedPerProjectMonths();
            }

        },
        'per_project_months.to'(val) {
            if(val) {
                this.getCountTotalObligationPerProjectMonths();
                this.getCountTotalDisbursedPerProjectMonths();
            }
        },
        'per_project_months.program'(val) {
            if(val) {
                this.getCountTotalObligationPerProjectMonths();
                this.getCountTotalDisbursedPerProjectMonths();
            }
        },
        'per_project_months.appro'(val) {
            if(val) {
                this.getCountTotalObligationPerProjectMonthsAppro();
                this.getCountTotalDisbursedPerProjectMonthsAppro();
            }
        },
        'per_project_months.year'(val) {
            if(val) {
                this.getCountTotalObligationPerProjectMonths();
                this.getCountTotalDisbursedPerProjectMonths();
            }
        },
        'per_project_months.funding_source_id'(val) {
            if(val) {
                this.getCountTotalObligationPerProjectMonthsFundingSource();
                this.getCountTotalDisbursedPerProjectMonthsFundingSource();
            }
        },
        'per_project_year.program'(val) {
            if(val) {
                this.getCountTotalAllotmentPerProject();
                this.getCountTotalObligationPerProject();
                this.getCountTotalDisbursedPerProject();
            }
        },
        'per_project_year.funding_source_id'(val) {
            if(val) {
                this.getCountTotalObligationPerProjectFundingSource();
                this.getCountTotalDisbursedPerProjectFundingSource();
                this.getCountTotalAllotmentPerProject();
            }
        },
        'per_project_year.year'(val) {
           if(val) {
                this.getCountTotalAllotmentPerProject();
                this.getCountTotalObligationPerProject();
                this.getCountTotalDisbursedPerProject();
           }
        },
        'per_project_year.appro'(val) {
           if(val) {
                this.getCountTotalAllotmentPerProject();
                this.getCountTotalObligationPerProjectAppro();
                this.getCountTotalDisbursedPerProjectAppro();
           }
        },
        //PER OBJECT CODE
        'per_project_object_code_year.appro'(val) {
            if(val) {
                this.getCountTotalAllotmentPerProjectObjectCode();
                this.getCountTotalObligationPerProjectObjectCodeAppro();
                this.getCountTotalDisbursedPerProjectObjectCodeAppro();
            }
        },
        'per_project_object_code_year.objCode'(val) {
            if(val) {
                this.getCountTotalAllotmentPerProjectObjectCode();
                this.getCountTotalObligationPerProjectObjectCode();
                this.getCountTotalDisbursedPerProjectObjectCode();
                this.getCountTotalBudgetBorrowedPerObjectCode();
                this.getCountTotalBorrowedPerObjectCode();
            }
        },
        'per_project_object_code_year.funding_source_id'(val) {
            if(val) {
                this.getCountTotalAllotmentPerProjectObjectCode();
                this.getCountTotalObligationPerProjectObjectCodeFundingSource();
                this.getCountTotalDisbursedPerProjectObjectCodeFundingSource();

            }
        },
         'per_project_object_code_year.year'(val) {
            if(val) {
                this.getCountTotalAllotmentPerProjectObjectCode();
                this.getCountTotalObligationPerProjectObjectCode();
                this.getCountTotalDisbursedPerProjectObjectCode();
                this.getCountTotalBudgetBorrowedPerObjectCode();
                this.getCountTotalBorrowedPerObjectCode();


            }
        },
        'per_project_object_code_year.program'(val) {
            if(val) {
                this.getCountTotalAllotmentPerProjectObjectCode();
                this.getCountTotalObligationPerProjectObjectCode();
                this.getCountTotalDisbursedPerProjectObjectCode();
                this.getCountTotalBudgetBorrowedPerObjectCode();
                this.getCountTotalBorrowedPerObjectCode();


            }
        },
        'per_project_object_code_months.appro'(val) {
            if(val) {
                this.getCountTotalObligationPerProjectObjectCodeMonthsAppro();
                this.getCountTotalDisbursedPerProjectObjectCodeMonthsAppro();
            }
        },
        'per_project_object_code_months.funding_source_id'(val) {
            if(val) {
                this.getCountTotalObligationPerProjectObjectCodeMonthsFundingSource();
                this.getCountTotalDisbursedPerProjectObjectCodeMonthsFundingSource();
            }
        },
        'per_project_object_code_months.year'(val) {
            if(val) {
                this.getCountTotalObligationPerProjectObjectCodeMonths();
                this.getCountTotalDisbursedPerProjectObjectCodeMonths();
                this.getCountTotalBudgetBorrowedPerObjectCodeMonths();
                this.getCountTotalBorrowedPerObjectCodeMonths();
            }
        },
        'per_project_object_code_months.objCode'(val) {
            if(val) {
                this.getCountTotalObligationPerProjectObjectCodeMonths();
                this.getCountTotalDisbursedPerProjectObjectCodeMonths();
                this.getCountTotalBudgetBorrowedPerObjectCodeMonths();
                this.getCountTotalBorrowedPerObjectCodeMonths();

            }
        },
        'per_project_object_code_months.program'(val) {
            if(val) {
                this.getCountTotalObligationPerProjectObjectCodeMonths();
                this.getCountTotalDisbursedPerProjectObjectCodeMonths();
                this.getCountTotalBudgetBorrowedPerObjectCodeMonths();
                this.getCountTotalBorrowedPerObjectCodeMonths();

            }
        },
        'per_project_object_code_months.obj_from'(val) {
            if(val) {
                this.getCountTotalObligationPerProjectObjectCodeMonths();
                this.getCountTotalDisbursedPerProjectObjectCodeMonths();
                this.getCountTotalBudgetBorrowedPerObjectCodeMonths();
                this.getCountTotalBorrowedPerObjectCodeMonths();

            }
        },
        'per_project_object_code_months.obj_to'(val) {
            if(val) {
                this.getCountTotalObligationPerProjectObjectCodeMonths();
                this.getCountTotalDisbursedPerProjectObjectCodeMonths();
                this.getCountTotalBudgetBorrowedPerObjectCodeMonths();
                this.getCountTotalBorrowedPerObjectCodeMonths();

            }
        },

  },
    mounted() {
        this.getAllProgram();
        // this.getCountTotalAllotmentPerProject();
        // this.getCountTotalObligationPerProject();
        // this.getCountTotalDisbursedPerProject();
        this.getObjectCode();
        // this.getCountTotalAllotmentPerProjectObjectCode();
        // this.getCountTotalObligationPerProjectObjectCode();
        // this.getCountTotalDisbursedPerProjectObjectCode();
        this.getFundingSources();
    },
    methods: {
        async getFundingSources() {
            try {
                const response = await axios.get('api/get-funding-source')
                .then((response) => {
                this.funding_sources = response.data;  
                })
            } catch(error) {
                console.log("err", error);
                alert('Something went wrong!');
            }
        },
        roundOf(num) {
            return Number(num).toFixed(2);
        },
        toggleCheckbox() {
            this.checkbox = !this.checkbox
            this.$emit('setCheckboxVal', this.checkbox)
        },
        toggleCheckboxObj() {
            this.checkbox_obj = !this.checkbox_obj
            this.$emit('setCheckboxVal', this.checkbox_obj)
        },
        addComma(amount) {
            let num_parts = amount.toString().split(".");
            num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            return num_parts.join(".");
        },
        convertToPeso(amount) {
            let peso = "";
            
            peso = "₱" + amount;

            return peso;

        },
        async getAllProgram() {
            try {
                const response = await axios.get('api/get-all-program')
                .then((response) => {
                this.programs = response.data;
                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalAllotmentPerProject() {
            this.loading_project = true;
            try {
                const response = await axios.get('api/get-count-total-allotment-per-project/' + this.per_project_year.program + '/' + this.per_project_year.year)
                .then((response) => {

                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].amount;
                }

                this.per_project_total_allotment = total;
                this.loading_project = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalAllotmentPerProjectObjectCode() {
            this.loading_objCode = true;
            try {
                const response = await axios.get('api/get-count-total-allotment-per-project-object-code/' + this.per_project_object_code_year.program + '/' + this.per_project_object_code_year.year + '/' + this.per_project_object_code_year.objCode)
                .then((response) => {

                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].allotments;
                }

                this.per_project_object_code_total_allotment = total;
                this.loading_objCode = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalObligationPerProject() {
               this.loading_project = true;
            try {
                const response = await axios.get('api/get-count-total-obligation-per-project/' + this.per_project_year.program + '/' + this.per_project_year.year)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].obligations;
                }

                this.per_project_total_obligation = total;
                this.loading_project = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalObligationPerProjectAppro() {
               this.loading_project = true;
            try {
                const response = await axios.get('api/get-count-total-obligation-per-project-appro/' + this.per_project_year.program + '/' + this.per_project_year.appro + '/' + this.per_project_year.year)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].obligations;
                }

                this.per_project_total_obligation = total;
                this.loading_project = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalObligationPerProjectFundingSource() {
               this.loading_project = true;
            try {
                const response = await axios.get('api/get-count-total-obligation-per-project-funding-source/' + this.per_project_year.program + '/' + this.per_project_year.year + '/' + this.per_project_year.funding_source_id)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].obligations;
                }

                this.per_project_total_obligation = total;
                this.loading_project = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalObligationPerProjectMonths() {
               this.loading_months = true;
            try {
                const response = await axios.get('api/get-count-total-obligation-per-project-months/' + this.per_project_months.program + '/' + this.per_project_months.from + '/' + this.per_project_months.to + '/' + this.per_project_months.year)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].obligations;
                }

                this.per_project_total_obligation_months = total;
                this.loading_months = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalObligationPerProjectMonthsAppro() {
               this.loading_months = true;
            try {
                const response = await axios.get('api/get-count-total-obligation-per-project-months-appro/' + this.per_project_months.program + '/' + this.per_project_months.appro + '/' + this.per_project_months.from + '/' + this.per_project_months.to + '/' + this.per_project_months.year)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].obligations;
                }

                this.per_project_total_obligation_months = total;
                this.loading_months = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalObligationPerProjectMonthsFundingSource() {
               this.loading_months = true;
            try {
                const response = await axios.get('api/get-count-total-obligation-per-project-months-funding-source/' + this.per_project_months.program + '/' + this.per_project_months.funding_source_id + '/' + this.per_project_months.from + '/' + this.per_project_months.to + '/' + this.per_project_months.year)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].obligations;
                }

                this.per_project_total_obligation_months = total;
                this.loading_months = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalBudgetBorrowedPerObjectCode() {
            try {
                const response = await axios.get('api/get-count-total-budget-borrowed-per-object-code/' + this.per_project_object_code_year.program + '/' + this.per_project_object_code_year.year + '/' + this.per_project_object_code_year.objCode)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].modified_amount_borrow;
                }

                this.per_project_object_code_total_budget_borrowed = total;
                console.log("budget borrowed", this.per_project_object_code_total_budget_borrowed);

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalBudgetBorrowedPerObjectCodeMonths() {
            try {
                const response = await axios.get('api/get-count-total-budget-borrowed-per-object-code-months/'  + this.per_project_object_code_months.program + '/' + this.per_project_object_code_months.objCode + '/' + this.per_project_object_code_months.obj_from + '/' + this.per_project_object_code_months.obj_to + '/' + this.per_project_object_code_months.year)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].modified_amount_borrow;
                }

                this.per_project_object_code_total_budget_borrowed_months = total;
                console.log("budget borrowed months", this.per_project_object_code_total_budget_borrowed_months);

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalBorrowedPerObjectCode() {
            try {
                const response = await axios.get('api/get-count-total-borrowed-per-object-code/' + this.per_project_object_code_year.program + '/' + this.per_project_object_code_year.year + '/' + this.per_project_object_code_year.objCode)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].modified_amount_borrow;
                }

                this.per_project_object_code_total_borrowed = total;
                console.log("borrowed", this.per_project_object_code_total_borrowed);

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalBorrowedPerObjectCodeMonths() {
            try {
                const response = await axios.get('api/get-count-total-borrowed-per-object-code-months/' + this.per_project_object_code_months.program + '/' + this.per_project_object_code_months.objCode + '/' + this.per_project_object_code_months.obj_from + '/' + this.per_project_object_code_months.obj_to + '/' + this.per_project_object_code_months.year)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].modified_amount_borrow;
                }

                this.per_project_object_code_total_borrowed_months = total;
                console.log("borrowed months", this.per_project_object_code_total_borrowed_months);

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalObligationPerProjectObjectCode() {
            this.loading_objCode = true;
            try {
                const response = await axios.get('api/get-count-total-obligation-per-project-object-code/' + this.per_project_object_code_year.program + '/' + this.per_project_object_code_year.year + '/' + this.per_project_object_code_year.objCode)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].obligations;
                }

                this.per_project_object_code_total_obligation = total;
                this.loading_objCode = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalObligationPerProjectObjectCodeAppro() {
            this.loading_objCode = true;
            try {
                const response = await axios.get('api/get-count-total-obligation-per-project-object-code-appro/' + this.per_project_object_code_year.program + '/' + this.per_project_object_code_year.appro + '/' + this.per_project_object_code_year.year + '/' + this.per_project_object_code_year.objCode)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].obligations;
                }

                this.per_project_object_code_total_obligation = total;
                this.loading_objCode = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalObligationPerProjectObjectCodeFundingSource() {
            this.loading_objCode = true;
            try {
                const response = await axios.get('api/get-count-total-obligation-per-project-object-code-funding-source/' + this.per_project_object_code_year.program + '/' + this.per_project_object_code_year.funding_source_id + '/' + this.per_project_object_code_year.year + '/' + this.per_project_object_code_year.objCode)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].obligations;
                }

                this.per_project_object_code_total_obligation = total;
                this.loading_objCode = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalObligationPerProjectObjectCodeMonths() {
            this.loading_objCode_months = true;
            try {
                const response = await axios.get('api/get-count-total-obligation-per-project-object-code-months/' + this.per_project_object_code_months.program + '/' + this.per_project_object_code_months.objCode + '/' + this.per_project_object_code_months.obj_from + '/' + this.per_project_object_code_months.obj_to + '/' + this.per_project_object_code_months.year)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].obligations;
                }

                this.per_project_object_code_total_obligation_months = total;
                this.loading_objCode_months = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalObligationPerProjectObjectCodeMonthsAppro() {
            this.loading_objCode_months = true;
            try {
                const response = await axios.get('api/get-count-total-obligation-per-project-object-code-months-appro/' + this.per_project_object_code_months.program + '/' + this.per_project_object_code_months.appro + '/' + this.per_project_object_code_months.objCode + '/' + this.per_project_object_code_months.obj_from + '/' + this.per_project_object_code_months.obj_to + '/' + this.per_project_object_code_months.year)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].obligations;
                }

                this.per_project_object_code_total_obligation_months = total;
                this.loading_objCode_months = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalObligationPerProjectObjectCodeMonthsFundingSource() {
            this.loading_objCode_months = true;
            try {
                const response = await axios.get('api/get-count-total-obligation-per-project-object-code-months-funding-source/' + this.per_project_object_code_months.program + '/' + this.per_project_object_code_months.funding_source_id + '/' + this.per_project_object_code_months.objCode + '/' + this.per_project_object_code_months.obj_from + '/' + this.per_project_object_code_months.obj_to + '/' + this.per_project_object_code_months.year)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].obligations;
                }

                this.per_project_object_code_total_obligation_months = total;
                this.loading_objCode_months = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalDisbursedPerProject() {
            this.loading_project = true;
            try {
                const response = await axios.get('api/get-count-total-disbursed-per-project/' + this.per_project_year.program  + '/' + this.per_project_year.year)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].gross;
                }

                this.per_project_total_disbursed = total;

                console.log("per project total disbursed", response.data);

                this.loading_project = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalDisbursedPerProjectAppro() {
            this.loading_project = true;
            try {
                const response = await axios.get('api/get-count-total-disbursed-per-project-appro/' + this.per_project_year.program  + '/' + this.per_project_year.appro + '/' + this.per_project_year.year)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].gross;
                }

                this.per_project_total_disbursed = total;

                console.log("per project total disbursed", response.data);

                this.loading_project = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalDisbursedPerProjectFundingSource() {
            this.loading_project = true;
            try {
                const response = await axios.get('api/get-count-total-disbursed-per-project-funding-source/' + this.per_project_year.program  + '/' + this.per_project_year.year + '/' + this.per_project_year.funding_source_id )
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].gross;
                }

                this.per_project_total_disbursed = total;

                console.log("per project total disbursed", response.data);

                this.loading_project = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalDisbursedPerProjectMonths() {
            this.loading_months = true;
            try {
                const response = await axios.get('api/get-count-total-disbursed-per-project-months/' + this.per_project_months.program  + '/' + this.per_project_months.from + '/' + this.per_project_months.to + '/' + this.per_project_months.year)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].gross;
                }

                this.per_project_total_disbursed_months = total;

                console.log("per project total disbursed", response.data);

                this.loading_months = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalDisbursedPerProjectMonthsAppro() {
            this.loading_months = true;
            try {
                const response = await axios.get('api/get-count-total-disbursed-per-project-months-appro/' + this.per_project_months.program  + '/' + this.per_project_months.appro + '/' + this.per_project_months.from + '/' + this.per_project_months.to + '/' + this.per_project_months.year)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].gross;
                }

                this.per_project_total_disbursed_months = total;

                console.log("per project total disbursed", response.data);

                this.loading_months = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalDisbursedPerProjectMonthsFundingSource() {
            this.loading_months = true;
            try {
                const response = await axios.get('api/get-count-total-disbursed-per-project-months-funding-source/' + this.per_project_months.program + '/' + this.per_project_months.funding_source_id +  '/' + this.per_project_months.from + '/' + this.per_project_months.to + '/' + this.per_project_months.year)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].gross;
                }

                this.per_project_total_disbursed_months = total;

                console.log("per project total disbursed", response.data);

                this.loading_months = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalDisbursedPerProjectObjectCode() {
             this.loading_objCode = true;
            try {
                const response = await axios.get('api/get-count-total-disbursed-per-project-object-code/' +  this.per_project_object_code_year.program + '/' + this.per_project_object_code_year.year + '/' + this.per_project_object_code_year.objCode)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].gross;
                }

                this.per_project_object_code_total_disbursed = total;
                this.loading_objCode = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalDisbursedPerProjectObjectCodeAppro() {
             this.loading_objCode = true;
            try {
                const response = await axios.get('api/get-count-total-disbursed-per-project-object-code-appro/' +  this.per_project_object_code_year.program + '/' + this.per_project_object_code_year.appro + '/' + this.per_project_object_code_year.year + '/' + this.per_project_object_code_year.objCode)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].gross;
                }

                this.per_project_object_code_total_disbursed = total;
                this.loading_objCode = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalDisbursedPerProjectObjectCodeFundingSource() {
             this.loading_objCode = true;
            try {
                const response = await axios.get('api/get-count-total-disbursed-per-project-object-code-funding-source/' +  this.per_project_object_code_year.program + '/' + this.per_project_object_code_year.funding_source_id + '/' + this.per_project_object_code_year.year + '/' + this.per_project_object_code_year.objCode)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].gross;
                }

                this.per_project_object_code_total_disbursed = total;
                this.loading_objCode = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalDisbursedPerProjectObjectCodeMonths() {
             this.loading_objCode_months = true;
            try {
                const response = await axios.get('api/get-count-total-disbursed-per-project-object-code-months/' +  this.per_project_object_code_months.program + '/' + this.per_project_object_code_months.objCode + '/' + this.per_project_object_code_months.obj_from + '/' + this.per_project_object_code_months.obj_to + '/' + this.per_project_object_code_months.year)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].gross;
                }

                this.per_project_object_code_total_disbursed_months = total;
                this.loading_objCode_months = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalDisbursedPerProjectObjectCodeMonthsAppro() {
             this.loading_objCode_months = true;
            try {
                const response = await axios.get('api/get-count-total-disbursed-per-project-object-code-months-appro/' +  this.per_project_object_code_months.program + '/' + this.per_project_object_code_months.appro + '/' + this.per_project_object_code_months.objCode + '/' + this.per_project_object_code_months.obj_from + '/' + this.per_project_object_code_months.obj_to + '/' + this.per_project_object_code_months.year)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].gross;
                }

                this.per_project_object_code_total_disbursed_months = total;
                this.loading_objCode_months = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getCountTotalDisbursedPerProjectObjectCodeMonthsFundingSource() {
             this.loading_objCode_months = true;
            try {
                const response = await axios.get('api/get-count-total-disbursed-per-project-object-code-months-funding-source/' +  this.per_project_object_code_months.program + '/' + this.per_project_object_code_months.funding_source_id + '/' + this.per_project_object_code_months.objCode + '/' + this.per_project_object_code_months.obj_from + '/' + this.per_project_object_code_months.obj_to + '/' + this.per_project_object_code_months.year)
                .then((response) => {
        
                let data = response.data;
                let total = 0;
                let len = data.length;
                for(let i=0; i<len; i++) {
                    total = total + data[i].gross;
                }

                this.per_project_object_code_total_disbursed_months = total;
                this.loading_objCode_months = false;

                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
        async getObjectCode() {
            try {
                const response = await axios.get('api/get-object-code')
                .then((response) => {
                this.object_codes = response.data;  
                })
            } catch(error) {
                console.log("err", error);
                //alert('Something went wrong!');
            }
        },
    }
}
</script>